//@extend-elements
//.categories ul li a, .categories ul li a:active, .categories ul li a:hover
%portfolio_extend_1 {
  line-height: 24px;
  background: #fff;
  color: #4e5961;
  text-decoration: none;
}

//.portfolio-thumb .enlarge, .portfolio-thumb .link
%portfolio_extend_2 {
  display: inline-block;
  margin: 0;
  margin-top: -25px;
  font-size: 50px;
  line-height: 50px;
  color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  height: 50px;
  width: 64px;
  top: 40%;
  left: 50%;
  text-align: center;
  z-index: 3;
}

//.portfolio-thumb .enlarge.centered, .portfolio-thumb .link.centered
%portfolio_extend_3 {
  margin-left: -32px;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translateY(-200px);
  //Instead of the line below you could use @include transition-delay($delay-1, $delay-2, $delay-3, $delay-4, $delay-5, $delay-6, $delay-7, $delay-8, $delay-9, $delay-10)
  transition-delay: 0s;
}

.portfolio-item {
  position: relative;
  margin-bottom: 30px;
  transform: scale(1);
  opacity: 1;
  transition: all 0.4s ease-out;
  &.filtered {
    transform: scale(0.5);
    opacity: 0.2;
    cursor: default;
    a {
      cursor: default;
    }
    .enlarge {
      display: none;
    }
    .link {
      display: none;
    }
    .overlay-mask {
      display: none;
    }
    .project-title {
      display: none;
    }
  }
}
.portfolio-thumb {
  display: block;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  border-radius: 15px;
  .overlay-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #b749ca;
    opacity: 0;
    filter: alpha(opacity=0);
    z-index: 1;
    transition: opacity 0.3s ease-out;
  }
  &:hover {
    .overlay-mask {
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
    .enlarge {
      transform: translateX(0);
      opacity: 1;
      filter: alpha(opacity=100);
      transition: all 0.3s ease-out 0.3s;
      &.centered {
        transform: translateY(0);
        transition-delay: 0.3s;
      }
    }
    .link {
      transform: translate(0);
      opacity: 1;
      filter: alpha(opacity=100);
      transition: all 0.3s ease-out 0.6s;
      &.centered {
        transform: translateY(0);
        transition-delay: 0.3s;
      }
    }
    .project-title {
      bottom: 0;
      transition: bottom 0.3s ease-out 0.1s, color 0.2s ease-out 0s;
    }
  }
  .enlarge {
    @extend %portfolio_extend_2;
    margin-left: -84px;
    transform: translateX(-200px);
    transition: all 0.3s ease-out;
    &.centered {
      @extend %portfolio_extend_3;
    }
  }
  .link {
    @extend %portfolio_extend_2;
    margin-left: 20px;
    transform: translateX(200px);
    transition: all 0.3s ease-out;
    &.centered {
      @extend %portfolio_extend_3;
    }
  }
  .project-title {
    display: block;
    width: 100%;
    position: absolute;
    bottom: -100px;
    background: #fff;
    margin: 0;
    padding: 20px 0;
    font-size: 21px;
    font-weight: 300;
    color: #777;
    text-align: center;
    z-index: 2;
    transition: bottom 0.4s ease-out, color 0.2s ease-out;
    &:hover {
      color: #5cc9df;
    }
  }
}
.categories ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
  li {
    display: inline-block;
    padding: 0;
    line-height: 24px;
    background: transparent;
    margin: 0;
    margin-left: 5px;
    margin-bottom: 10px;
    a {
      display: block;
      font-size: 18px;
      font-weight: 500;
      padding: 10px 20px;
      border-radius: 5px;
      border: 2px solid transparent;
      transition: all 0.2s ease-out;
      @extend %portfolio_extend_1;
      &:active {
        @extend %portfolio_extend_1;
      }
      &:hover {
        @extend %portfolio_extend_1;
        color: #fff;
        background: #9c27b0;
      }
    }
    &.active a {
      color: #fff;
      background: #9c27b0;
    }
  }
}
.projects-container .row {
  transition: height 0.5s ease-out;
}
.no-opacity .portfolio-item.filtered {
  display: none;
}
.scrollimation .portfolio-thumb {
  transform: translateY(100px);
  opacity: 0;
  transition: opacity 0.4s ease-out, transform 0.4s ease-out;
  &.in {
    transform: translateY(0px);
    opacity: 1;
  }
}
.touch .scrollimation .portfolio-thumb {
  transform: translateY(0px);
  opacity: 1;
}
