.jqstooltip {
  box-sizing: content-box;
}

.sparkline-bar,
.sparkline-line,
.sparkline-inline {
  width: 100%;
}

.sparkline-bar canvas,
.sparkline-line canvas,
.sparkline-inline canvas {
  width: 100% !important;
}
